// 深圳提单
import { request } from "@/utils/request.js";

/**
 * @description 深圳提单分页查询
 * @typedef {Object} blnoPageDto
 * @property {string} blnoPageDto.code 类型,NB,SH,SZ,这里是深圳
 * @property {string} blnoPageDto.blno 提单号
 * @property {string} blnoPageDto.webname 场站
 * @property {string} [blnoPageDto.vesselsName] 船名
 * @property {string} [blnoPageDto.voyage] 航次
 * @property {string} [blnoPageDto.start] 开始时间
 * @property {string} [blnoPageDto.ctnApplyBeginFlag] 开港状态 0:未开港 1:已开港 '':全部
 * @property {string} [blnoPageDto.portCloseFlag] 截单状态 0:未截单 1:已截单 '':全部
 * @property {string} [blnoPageDto.ctnApplyEndFlag] 截港状态 0:未截港 1:已截港 '':全部
 * @property {string} [blnoPageDto.start] 开始时间
 * @property {string} [blnoPageDto.end] 结束时间
 * @property {number} blnoPageDto.pageSize 每页条数
 * @property {number} blnoPageDto.pageNum 页码
 * @property {string} [blnoPageDto.ctnApplyEnd] 是否截港
 * @property {string} [blnoPageDto.portClose] 是否截单
 * @property {string} [blnoPageDto.customFlag] 海关放行 1.已放行 0.未放行 ''全部
 * @property {string} [blnoPageDto.queryFlag] 全局搜索orderTimeTypeOptions
 * @property {string} [blnoPageDto.nppStatus] 码头放行 1.已放行 0.未放行 ''全部
 * @param {blnoPageDto} param
 * @returns
 */
export const querySZBlnoByPage = (param) => {
    return request("/blno/userBlnoSz/queryBlnoPage", "post", param);
};

/**
 * @description 深圳提单详情
 * @param {string} id
 * @returns
 */
export const querySZBlnoDetail = (id) => {
    return request(
        "/blno/userBlnoSz/queryBlnoDetails",
        "post",
        id,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
};

/**
 * @description 深圳提单分页查历史
 * @typedef {Object} blnoHisPageDto
 * @property {string} [blnoHisPageDto.blno] 提单号
 * @property {string} [blnoHisPageDto.code] 类型,NB,SH,SZ,这里是深圳
 * @property {number} blnoHisPageDto.pageNum 页码
 * @property {number} blnoHisPageDto.pageSize 每页条数
 * @property {string} [blnoHisPageDto.vesselsName] 船名
 * @property {string} [blnoHisPageDto.voyage] 航次
 * @param {blnoHisPageDto} param
 */
export const querySZBlnoHistory = (param) => {
    return request("/blno/userBlnoSz/queryDeleted", "post", param);
};

/**
 * @description 深圳提单获取船名航次
 * @param {string} code 类型,NB,SH,SZ,这里是深圳
 * @returns
 */
export const querySZVevo = (code) => {
    return request("/blno/userBlnoSz/queryVeVo", "get", code, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
};

/**
 * @description 深圳提单获取提单号
 * @param {string} code 类型,NB,SH,SZ,这里是深圳
 */
export const querySZBlno = (code) => {
    return request("/blno/userBlnoSz/queryBlno", "get", code, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
};

/**
 * @description 深圳提单修改船期
 * @typedef {Object} idVvDto
 * @property {string} [idVvDto.companyId] 公司id
 * @property {array<number>} idVvDto.ids 提单id
 * @property {string} idVvDto.vesselsName 船名
 * @property {string} idVvDto.voyage 航次
 * @property {string} [idVvDto.role] 当前用户角色/PERSONAL/ENTERPISE
 * @param {idVvDto} param
 */
export const updateSZVv = (param) => {
    return request("/blno/userBlnoSz/updateVv", "post", param);
};

/**
 * @description 深圳提单历史撤回
 * @typedef {Object} blnoDtos
 * @property {string} blnoDtos.blno 提单号
 * @property {string} blnoDtos.code 类型,NB,SH,SZ,这里是深圳\
 * @property {string} [idVvDto.companyId] 公司id
 * @property {string} [idVvDto.role] 当前用户角色/PERSONAL/ENTERPISE
 * @property {string} blnoDtos.isCheck 是否检测整/拼
 * @property {string} [blnoDtos.vesselsName] 船名
 * @property {string} [blnoDtos.voyage] 航次
 * @property {string} blnoDtos.webname 场站
 * @property {string} [blnoDtos.remark] 备注
 * @property {string} [blnoDtos.psType] 整/拼标识 整票为1 分票为主票提单号
 * @param {array<blnoDtos>} param
 */

export const recallSZBlno = (param) => {
    return request("/blno/userBlnoSz/recallDeleted", "post", param);
};

/**
 * @description 深圳提单主提单号修改
 * @typedef {Object} blnoDtos
 * @property {string} blnoDtos.blnoMain 主提单号
 * @property {string} blnoDtos.companyId 公司id
 * @property {array<number>} blnoDtos.ids 提单id
 * @property {string} blnoDtos.role 当前用户角色/PERSONAL/ENTERPISE
 * @param {blnoDtos} param
 */
export const updateSZBlnoMain = (param) => {
    return request("/blno/userBlnoSz/updateBlnoMain", "post", param);
};


